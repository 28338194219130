import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { LandingComponent } from './examples/landing/landing.component';
import { LoginComponent } from './examples/login/login.component';
import { ProfileComponent } from './examples/profile/profile.component';
import { NucleoiconsComponent } from './components/nucleoicons/nucleoicons.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './examples/product/product.component';
import { MapsComponent } from './maps/maps.component';
import { TypoComponent } from './components/typo/typo.component';
import { MeatShopComponent } from './meatshop-price/meat-shop-price.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home',                component: ComponentsComponent },
    { path: 'http://apanjanmeatshop.com/index',component: ComponentsComponent },
    { path: 'https://apanjanmeatshop.com/index',component: ComponentsComponent },
    { path: 'http://apanjanmeatshop.far-tech.co.in/index',component: ComponentsComponent },
    { path: 'landing',     component: LandingComponent },
    { path: 'login',       component: LoginComponent },
    { path: 'profile',     component: ProfileComponent },
    { path: 'contact',     component: ContactComponent },
    { path: 'price',     component: MeatShopComponent },

    { path: 'product',     component: ProductComponent },
    { path: 'map',     component: MapsComponent },
// { path: 'checkpincode',     component: TypoComponent },
 { path: 'checkpincode',     redirectTo: 'Projonmo_Foundation_Application_Form.pdf' },



    



];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{useHash:true})
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
