import { Component, OnInit, Renderer2, OnDestroy,VERSION } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionConfig } from '@ng-bootstrap/ng-bootstrap';
import * as Rellax from 'rellax';
import { AppService } from 'app/app.service';
import { DayByDayPrice } from 'app/model/DayByDayPrice';

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.scss']
})

export class ComponentsComponent implements OnInit, OnDestroy {
    name = 'Angular ' + VERSION.major;
    pdfSource = "https://drive.google.com/file/d/1pWTBiXQJXt0-dgLpjVJ9CblLu4KaIyob/view?usp=sharing";
    data : Date = new Date();

    page = 4;
    page1 = 5;
    page2 = 3;
    focus;
    focus1;
    focus2;
    dayByDayPrice=new DayByDayPrice();
    date: {year: number, month: number};
    model: NgbDateStruct;

    public isCollapsed = true;
    public isCollapsed1 = true;
    public isCollapsed2 = true;

    state_icon_primary = true;

    constructor( private renderer : Renderer2, config: NgbAccordionConfig,
        private appService: AppService)
         {
        config.closeOthers = true;
        config.type = 'info';
    }
    isWeekend(date: NgbDateStruct) {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getDay() === 0 || d.getDay() === 6;
    }

    isDisabled(date: NgbDateStruct, current: {month: number}) {
        return date.month !== current.month;
    }

    ngOnInit() {    
            // this.appService
            //   .getPrice()
            //   .subscribe(dayByDayPrice =>{                 
            //     this.dayByDayPrice=this.dayByDayPrice;//dayByDayPrice[0];
            //    // console.log("price boneless"+this.dayByDayPrice.status.price_bonelessmeat)    ;            
            //       })
          
         
      var rellaxHeader = new Rellax('.rellax-header');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('index-page');
    }
    ngOnDestroy(){
        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('index-page');
    }
}
