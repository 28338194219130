import { Component, OnInit } from '@angular/core';
//import { StudentService } from '../student.service';
//import { Student } from '../student';

import {ActivatedRoute, Params, Router} from '@angular/router';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  data : Date = new Date();

constructor(// private studentService: StudentService,
     private router: Router ) { }

  ngOnInit() {}
//model = new Student();
  addInfo(){   
     // this.studentService
       // .addStudentInfo(this.model)
        //.subscribe(()=> this.goBack());
  }
   goBack(){
    this.router.navigate(['/home']);
  }
}
