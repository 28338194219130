import { Component, OnInit } from '@angular/core';


import {ActivatedRoute, Params, Router} from '@angular/router';
import { DayByDayPrice } from '../model/DayByDayPrice';
import { AppService } from '../app.service';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
    selector: 'app-meat-shop-price',
    templateUrl: './meat-shop-price.component.html',
    styleUrls: ['./meat-shop-price.component.scss']
})

export class MeatShopComponent implements OnInit {
  data : Date = new Date();
  price: any[];
  daybyDayPrice: DayByDayPrice;
  successfullmessage:boolean=false;
   loading = true;
   error: any;
   form: FormGroup;
   imageURL: string;
   //uploadForm: FormGroup;
   uploadResponse;
constructor( private priceService: AppService,private formBuilder: FormBuilder,
 
     private router: Router ) { 
      this.form = this.formBuilder.group({
        avatar: ['']
      });
     }
     onFileSelect(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.form.get('avatar').setValue(file);
      }
    }
  ngOnInit() {
   //this.daybyDayPrice=this.priceService.getPrice();
   // this.daybyDayPrice=this.priceService.daybyDayPrice;
   this.getlastData();
}
getlastData(): void {
  this.priceService.getPrice().subscribe(
    (res) => {
      this.daybyDayPrice = res;
    },
    (err) => {
      this.error = err;
    }
  );
}
setData(price){
this.daybyDayPrice=price;
console.log('class data '+this.daybyDayPrice);
}
showPreview(event) {
  const file = (event.target as HTMLInputElement).files[0];
  this.form.patchValue({
    avatar: file
  });
  this.form.get('avatar').updateValueAndValidity()

  // File Preview
  const reader = new FileReader();
  reader.onload = () => {
    this.imageURL = reader.result as string;
  }
  reader.readAsDataURL(file)
}

  addInfo(){   
    const formData = new FormData();
    formData.append('avatar', this.form.get('avatar').value);

    this.priceService.uploadFile(formData).subscribe(
      (res) => {
        this.uploadResponse = res;
          console.log(res);
      },
      (err) => {  
        console.log(err);
      }
    );
    //  this.priceService
    //    .updatePrice(this.daybyDayPrice)
    //     .subscribe(()=> this.goBack());
  }
   goBack(){
    this.router.navigate(['/home']);
  }
}
