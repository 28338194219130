import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { ExamplesModule } from './examples/examples.module';
import { NguiMapModule} from '@ngui/map';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { MapsComponent } from './maps/maps.component';
import { ContactComponent } from './contact/contact.component';
import { AppService } from './app.service';
import { HttpModule } from '@angular/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
//import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MeatShopComponent } from './meatshop-price/meat-shop-price.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        MapsComponent,
        ContactComponent,
        MeatShopComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        HttpModule,
        HttpClientModule,
       // GraphQLModule,
        ComponentsModule,
        ExamplesModule,
        ReactiveFormsModule,
       // PdfViewerModule,
        NguiMapModule.forRoot({apiUrl: 'https://maps.google.com/maps/api/js?key=AIzaSyAP1-X0n_-Ncb29u7vHx0IrmKaokSw4Iss'})
    ],
    providers: [AppService,{provide:LocationStrategy,useClass:HashLocationStrategy}],
    bootstrap: [AppComponent]
})
export class AppModule { }
