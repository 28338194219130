import { Component, OnInit, Input } from '@angular/core';
import { DayByDayPrice } from '../../model/DayByDayPrice';
//import { DayByDayPrice } from 'app/model/DayByDayPrice';
//import { AppService } from 'app/app.service';
//import { Apollo } from 'apollo-angular';
//import gql from "graphql-tag";
@Component({
    selector: 'app-typography',
    templateUrl: './typography.component.html',
    styleUrls: ['./typography.component.scss']
})
export class TypographyComponent implements OnInit {
  price: any[];
  daybyDayPrice: DayByDayPrice;
  successfullmessage:boolean=false;
   loading = true;
   error: any;
   constructor() { }

  ngOnInit() {
this.getlastData();
  }

  getlastData(){
    // this.apollo
    //   .query<any>({
    //     query: gql`
    //       {
    //         findAllPrices
    //         {
    //           id
    //           pricebroiler
    //           priceRodail 
    //           pricebonelessmeat
    //           pricechickenkeema
    //           pricechickenshredded
    //           pricechickengarlic
    //           pricechickentangri
    //           pricechickenslice
    //           pricechickenwings
    //           pricechickenkassa
    //           priceKalBirds
    //           priceLayerBirds
            
                 
    //             }
    //                   }
    //     `
    //   })
    //   .subscribe(
    //     ({ data, loading }) => {
    //       if(data.findAllPrices.length>0){
    //         let maxrow=data.findAllPrices.length;
    //      // this.price = data && data.findAllPrices[maxrow];
    //       this.price=data.findAllPrices[maxrow-1];
    //      // console.log(this.price);
    //       this.setData(this.price);
    //       this.loading = loading;
    //       }
    //     },
    //     error => {
    //       this.loading = false;
    //       this.error = error;
    //     }
    //   );
    //   //return this.daybyDayPrice;
  }
  setData(price){
  this.daybyDayPrice=price;
  console.log('class data '+this.daybyDayPrice);
  }
}
