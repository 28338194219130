import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalBasic } from './modal/modal.component';
import { ContactComponent } from '../contact/contact.component';
import { ProductComponent } from '../examples/product/product.component';
import { TypoComponent } from './typo/typo.component';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
//import { MapsComponent } from 'app/maps/maps.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module
      ],
    declarations: [
        ComponentsComponent,
        BasicelementsComponent,
        NavigationComponent,
        TypographyComponent,
        TypoComponent,

        NucleoiconsComponent,
        NotificationComponent,
        NgbdModalBasic,
        ProductComponent

       
       
    ],
    exports:[ ComponentsComponent,TypographyComponent,TypoComponent ]
})
export class ComponentsModule { }
