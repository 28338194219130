import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
//import { DayByDayPrice } from 'app/model/DayByDayPrice';
//import { Apollo } from 'apollo-angular';
//import gql from "graphql-tag";
import { DayByDayPrice } from '../../model/DayByDayPrice';

@Component({
  selector: 'ngbd-modal-component',
  templateUrl: './modal.component.html'
})
export class NgbdModalBasic  implements OnInit{
   closeResult: string;
  // price:DayByDayPrice;
  price: any[];
  daybyDayPrice: DayByDayPrice;
  successfullmessage:boolean=false;
   loading = true;
   error: any;
 
    // price:DayByDayPrice[] ;
    // price1:DayByDayPrice[] ;
  
    // arr:[];
    // strIntoObj: DayByDayPrice[];
//   @Input() 
//   set dayByDayPrice(message : DayByDayPrice[] ){
//     debugger;
// //     var pricearr;
// //     //this.price = message;
// //     //pricearr=message;
// //     pricearr=message.slice(1,-1);
// //     console.log(message.slice(1,-1));
// //     var a  = "[" + pricearr + "]";
// //     //this.price=a;  
// //     let str: string = "{" + pricearr + "}";
// //       this.strIntoObj = JSON.parse(str);
// //       console.log('abc'+ this.strIntoObj);
// //   //this.price1=this.strIntoObj;
// //   this.price =this.strIntoObj
   
//   }
//   get dayByDayPrice(){
//     return this.price;
//   }
   
    constructor(private modalService: NgbModal) {}
    ngOnInit() {
      this.getlastData();
      }
      
   
getlastData(){
  // this.apollo
  //   .query<any>({
  //     query: gql`
  //       {
  //         findAllPrices
  //         {
  //           id
  //           pricebroiler
  //           priceRodail 
  //           pricebonelessmeat
  //           pricechickenkeema
  //           pricechickenshredded
  //           pricechickengarlic
  //           pricechickentangri
  //           pricechickenslice
  //           pricechickenwings
  //           pricechickenkassa
  //           priceKalBirds
  //           priceLayerBirds
          
               
  //             }
  //                   }
  //     `
  //   })
  //   .subscribe(
  //     ({ data, loading }) => {
  //       if(data.findAllPrices.length>0){
  //         let maxrow=data.findAllPrices.length;
  //      // this.price = data && data.findAllPrices[maxrow];
  //       this.price=data.findAllPrices[maxrow-1];
  //      // console.log(this.price);
  //       this.setData(this.price);
  //       this.loading = loading;
  //       }
  //     },
  //     error => {
  //       this.loading = false;
  //       this.error = error;
  //     }
  //   );
  //   //return this.daybyDayPrice;
}
setData(price){
this.daybyDayPrice=price;
console.log('class data '+this.daybyDayPrice);
}
    
    open(content, type, modalDimension) {
        if (modalDimension === 'sm' && type === 'modal_mini') {
            this.modalService.open(content, { windowClass: 'modal-login modal-primary' }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        } else if (modalDimension == undefined && type === 'Login') {
          this.modalService.open(content, { windowClass: 'modal-login modal-primary' }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        } else {
            this.modalService.open(content).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }

    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }
}
