import { Component, OnInit } from '@angular/core';
//import { User } from 'app/model/user';
//import { AppService } from 'app/app.service';
import { Router } from '@angular/router';
import { User } from '../../model/user';
//import { Apollo } from 'apollo-angular';
//import gql from "graphql-tag";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    user: any[];
   // daybyDayPrice: DayByDayPrice;
    successfullmessage:boolean=false;
     loading = true;
     error: any;
    data : Date = new Date();
    focus;
    focus1;
    model= new User();

    constructor( private router: Router) { }
    checkInfo(){ // debugger; 
        // this.appservice
        //   .addUserInfo(this.model)
        //   .subscribe(()=> this.goBack());
    }
    
  getlastData(){
   
      //return this.daybyDayPrice;
  }
  setData(user){
  this.model=user;
  console.log('class data '+this.model);
  }

     goBack(){
      this.router.navigate(['/home']);
    }
    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent');
    }
    ngOnDestroy(){
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.remove('navbar-transparent');
    }

}
